import classNames from "classnames";
import { graphql, Link, StaticQuery } from "gatsby";
import React from "react";
import { styles } from "../utilities/styles";

const Reviews = () => (
  <StaticQuery
    query={graphql`
      query ReviewsAllPostQuery {
        allWpReview(sort: { fields: [date], order: DESC }) {
          edges {
            node {
              date(formatString: "DD, MMM YYYY")
              title
              slug
              content
              review {
                rating
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <>
        <div className="flex flex-wrap default-page">
          <div className="p-4 md:p-0 w-full md:w-1/2 m-auto my-8 text-grey-darker">
            {data.allWpReview.edges.map(({ node }) => (
              <div key={node.slug} className="mb-12">
                <h2>
                  <Link
                    to={`/review/${node.slug}/`}
                    dangerouslySetInnerHTML={{ __html: node.title }}
                    className="text-grey-darkest no-underline"
                  />
                </h2>
                <div dangerouslySetInnerHTML={{ __html: node.content }} />
                <p>
                  <a
                    className="text-grey-darker no-underline flex w-32 my-4"
                    href={process.env.GOOGLE_REVIEWS_PAGE}
                    rel="noopener noreferrer nofollow"
                    target="_blank"
                    title={`${node.review.rating} Star Google Review`}
                  >
                    {node.review.rating}/5
                  </a>
                </p>
                <p>
                  <a
                    className={classNames(
                      "block md:inline-block text-sm px-4 py-3 no-underline rounded",
                      styles.button.default
                    )}
                    href={process.env.GOOGLE_REVIEWS_PAGE}
                    rel="noopener noreferrer nofollow"
                    target="_blank"
                  >
                    See Review on Google
                  </a>
                </p>
              </div>
            ))}
          </div>
        </div>
      </>
    )}
  />
);

export default Reviews;
